import Button from "./Button";
import {useState} from "react";

export default () => {

    // let [date, setDate] = useState("");

    return <div className={"flex flex-col justify-center items-center p-4 gap-2"}>
        <img src={"./img/Pferd.png"} alt={"Pferd"}/>
        <h1 className={"text-2xl font-serif"}>Siggi – In Memoriam</h1>
        <p>✟ 17.11.2022</p>
        <form className={"form-control w-full max-w-xs"} method="post">
            <div className={"flex gap-0 w-full"}>
                <label className="label">
                    <span className="label-text">✹</span>
                </label>

                <input type="date" placeholder=""
                       // value={date}
                       name={"date"}
                       // onChange={(e) => setDate(e.target.value)}
                       className="input input-bordered w-full mr-1"/>
                <Button type={"submit"} text={""}/>
            </div>
        </form>
    </div>
}